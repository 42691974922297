import { Component, Inject, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterModule } from '@angular/router';
import { LoginService } from '../../services/authentication-services/login.service';
import { UserAuthentication } from '../../models/UserAuthentication';
import { SessionManagerService } from '../../shared/SharedServices/session-manager.service';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

import { MatButtonModule } from '@angular/material/button';
import { CommonModule, DOCUMENT, NgFor } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { BaseHttpclientService } from '../../services/base-httpclient.service';
import { withI18nSupport } from '@angular/platform-browser';
import { APIConstants } from '../../../environments/APIConstants';
import { MatRippleModule } from '@angular/material/core';
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { UserDailogComponent } from './userdailog/userdailog.component';
import { NewsDailogComponent } from './news-dailog/news-dailog.component';
import { AppModel } from '../../models/home/userhome/AppModel';
import { TruncatePipe } from '../../shared/truncate.pipe';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-userhome',
  templateUrl: './userhome.component.html',
  styleUrls: ['./userhome.component.css'],
  standalone: true,
  imports: [MatIconModule, MatCardModule, MatButtonModule, RouterLink, RouterModule, MatTabsModule, CommonModule, NgFor, MatRippleModule,
    UserDailogComponent, NewsDailogComponent,TruncatePipe]
})




export class UserhomeComponent implements OnInit {
  userData!: UserAuthentication;
  isLoggedIn = false;
  sessionId = "";
  currentNews = "News and any other notifications will be added here";
  currentAppName = "Communicator Optimization and Remediation Assistant"
  currentAppDes = "The CORA identifies factors that may be causing a poor Skype for Business or Microsoft Teams performance"
  currentImageIcon = "";
  currentHealthStatus = "/cora.png"
  domain : string = '';
  centered = true;
  allAppsData: AppModel[] = [];
  serviceApps: any = [];
  publicApps: any = [];
  restrictedApps: any = [];
  news: any = [];
  activeIndex = 0;
  totalTabs = 3;  // Total number of tabs

  constructor(
    public loginService: LoginService,
    private activeRoute: ActivatedRoute,
    private sessionManager: SessionManagerService,
    private webService: BaseHttpclientService,
    @Inject(DOCUMENT) private document: any
  ) {



 
    // this.loginService.login();
    this.activeRoute.queryParams
      .subscribe((params: any) => {



        if (params.id == undefined || params.id == "") {
          //in this case there is no id available so eiter need to redirect or what, need to validate for not just going back

          return;
        }
        if (!this.sessionId) {
          this.sessionId = params.id;
          this.sessionManager.setSessionId(this.sessionId);
        }


      }
      );

  }

  onArrowRight() {
    // Go to next tab, and wrap to the first tab if we're at the last one
    this.activeIndex = (this.activeIndex + 1) % this.totalTabs;
  }

  onArrowLeft() {
    // Go to previous tab, and wrap to the last tab if we're at the first one
    this.activeIndex = (this.activeIndex - 1 + this.totalTabs) % this.totalTabs;
  }

  getNews() {

   

    this.webService.get(APIConstants.EndPoints.GET_NEWS, "token=" + environment.conf).subscribe((data: any) => {
      this.news = data.response

    }, (err: any) => {



    })

  }


  getApps() {


    let templateUrl: string = localStorage.getItem("mstoken") ?? "";


    this.webService.get(APIConstants.EndPoints.GET_APPS, "token=" + templateUrl).subscribe((data: any) => {

      this.allAppsData = data
      
      for (var i = 0; i < this.allAppsData.length; i++) {

        var f = this.allAppsData[i];
  
        // for (var j = 0; j < f.value.length; j++) {


        //   // this.webService.getWithoutParam(String(f.value[j].link) + String(f.value[j].healthCheck)).subscribe((data: any) => {
        //   //   if (data.statusCode = '200') {

        //   //   }
        //   // }, (err: any) => {



        //   // })
        // }
        this.allAppsData[i].value.forEach(
          x => {

            var url=window.location.origin+x.link+String(x.healthCheck)
            console.log(url);
            if(x.type!="PowerApp"){


              this.webService.getWithoutParam(url).subscribe((data: any) => {
                if (data.statusCode = '200') {
                  x.health = true;
                }
                else {
                  x.health= false
                }
              }, (err: any) => {
              })

            }
           

          }
        )

        if (f.key == "PublicApps") {

          this.publicApps = f.value;

        }
        if (f.key == "ServiceApps") {

          this.serviceApps = f.value;

        }
        if (f.key == "Restricted") {

          this.restrictedApps = f.value;

        }


      }


    }, (err: any) => {

        console.log(err); 

    })


  }

  ngOnInit(): void {

    this.getApps();
    this.getNews();
    this.loginService.userData.subscribe((data: UserAuthentication) => {

      this.userData = data;

    });
    this.domain = this.document.location.Origin;
  }

  readonly dialog = inject(MatDialog);

  openDialog(dataApp: any) {
    this.dialog.open(UserDailogComponent
      , {
        data: { value: dataApp },
      }
    );

  }

  openNewsDialog(news: any) {
    this.dialog.open(NewsDailogComponent
      , {
        data: { value: news }
        ,width: "1000px"
        ,minHeight:"400px"
      }
    );
  }

  gotoLaunch(link: string) {
    window.open(link, "_blank");
  }
}

