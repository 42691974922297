import { Routes } from '@angular/router';
import { UserhomeComponent } from './home/userhome/userhome.component';

import { MsalGuard } from '@azure/msal-angular';

import { AuthenticationInitiatorComponent } from './authentication-initiator/authentication-initiator.component';
import { AddNewsComponent } from './home/userhome/add-news/add-news.component';
import { HelpAndSupportComponent } from './home/userhome/help-and-support/help-and-support.component';

export const routes: Routes = [{
    path:"home",component:UserhomeComponent,
    title:"Home",
    canActivate: [MsalGuard]
  },
  {
    path: 'add-news', component: AddNewsComponent,
    title: "Add News"
  },
  {
    path: 'help-support', component: HelpAndSupportComponent,
    title: "Help & Support"
  },
  
 
  {
    path:'signin-oidc',component:AuthenticationInitiatorComponent,
    title:"Authentication"
    
  },
  { path: '**', redirectTo: 'signin-oidc' },
  { path: '',   redirectTo: 'signin-oidc', pathMatch: 'full' },];
