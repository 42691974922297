
<app-toolbar-guest *ngIf="!isLoggedIn"></app-toolbar-guest>
<app-toolbar-user *ngIf="isLoggedIn"></app-toolbar-user>
<app-common-loader></app-common-loader>
<div class="background " role="main">
 
    <div class="container d-flex justify-content-center align-items-center text-center mt-4" *ngIf="isLoggedIn==false">

        <div class="text-center mat-elevation-z1" matRipple matRippleColor="#7030A044"  style="background-color: rgb(255, 255, 255); border-radius: 10px; height: 300px; width: 50%;">
            <img src="logo.png" class="text-center mt-4" height="90px">
            <h1 class="mt-4">Please Login to continue</h1>
            <p class="mt-4"><button mat-flat-button (click)="loginRedirect()">Login</button></p>
        </div>

    </div>


</div>

<div *ngIf="isLoggedIn">
    <router-outlet ></router-outlet>
</div>

<app-footer  [ngClass]="footerClass" ></app-footer>
